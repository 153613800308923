/* eslint-disable */
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useEffect, useState } from "react";
import axios from 'axios';
import ViewPage from "layouts/sections/components/ViwePage";
import { useParams } from "react-router-dom";


function StratifikasiuksPage() {
    const { slug } = useParams();
    const [tentang_uks, setTentangUKS] = useState({id:'-', konten: ''});
    const [label, setLabel] = useState([{value: '-', label:'...Tentang UKS...'}]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios({
            url: `/stratifikas-uks/${slug}`,
            method: "GET"
        }).then((e) => {
            if(e.data.rows){
                setTentangUKS(e.data.rows);
                setLabel(e.data.label);
                setLoading(false);
            }else{
                setTentangUKS([]);
                setLabel(e.data.label);
                setLoading(false);
            }
        })
    }, [slug]);

    return (
        <BaseLayout 
            title={tentang_uks.title || 'Memuat...'}
            breadcrumb={[
                { label: "HOME", route: "/" },
                { label: loading ? "Memuat..." : tentang_uks.title }
            ]}
            >
                <ViewPage title="" tabs={label} active={tentang_uks} slug={'stratifikasi-uks'}>                    
                    <p>Memuat...</p>
                </ViewPage>
        </BaseLayout>
    )
}

export default StratifikasiuksPage;