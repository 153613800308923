import { Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SearchListPage(params) {
  const { item } = params;
  const navigate = useNavigate();

  const formatDateIndo = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleDateString("id-ID", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const hanldeOpenLink = () => {
    if (!item.slug) {
        // eslint-disable-next-line no-alert
        alert("Link tidak tersedia");
    }

    navigate(item.slug);
  };

  const formatDate = formatDateIndo(item.date);

  return (
    <div>
      <Card
        style={{
            marginBottom: "0.8rem",
            padding: 10,
            cursor: 'pointer',
        }}
        onClick={hanldeOpenLink}
    >
        <div>
          <Typography variant="body2" style={{ fontWeight: "bold",
            '&:hover': {
                textDecoration: 'underline !important',
            }, }}>
            {item.title}
          </Typography>
          <Typography variant="caption">{item.deskripsi}</Typography>
          <Typography variant="caption">{formatDate}</Typography>
        </div>
      </Card>
    </div>
  );
}

export default SearchListPage;
