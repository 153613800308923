/* eslint-disable */
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function BeritaCard({ image, title, description, action, align, height, description_view }) {
  return (
    <Card
      style={{
        minHeight: height || '411px',
        maxHeight: height || '411px',
        marginBottom: '1.5rem'
      }}
    >
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
            // component="img"
            // src={image}
            // alt={title}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover'
          }}
            borderRadius="lg"
            width="100%"
            height="145px"
            position="relative"
            zIndex={1}
        />
        <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign={align || 'center'}>
        <MKBox mt={1} mb={3} style={{ height: '40px' }}>
            <MKTypography variant="h5" textTransform="capitalize"
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 16,
              }}>
              {title}
            </MKTypography>
        </MKBox>
        <MKBox mt={1} mb={3} style={{ height: '110px' }}>
            { description && <MKTypography variant="body2" component="p"
              style={{
                maxWidth: '100%',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 5,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 13,
                fontWeight: 500,
                textAlign: "left",
                lineHeight: 1.625,
                color: '#434654',
              }}
                dangerouslySetInnerHTML={{ __html: description || '' }}
              >
            </MKTypography>}
        </MKBox>
        {action.type === "external" ? (
            <MKButton
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color={action.color ? action.color : "dark"}
            >
                {action.label}
            </MKButton>
            ) : (
            <MKButton
                component={Link}
                to={action.route}
                variant="gradient"
                size="small"
                color={action.color ? action.color : "dark"}
            >
                {action.label}
            </MKButton>
        )}
      </MKBox>
    </Card>
  );
}

// Typechecking props for the BeritaCard
BeritaCard.propTypes = {
  image: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  description_view: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  align: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default BeritaCard;
