/* eslint-disable */
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

function InformationCard() {

    return (
        <MKBox component="section" py={1}>
            <Container>
                <Grid container spacing={3}>
                    <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
                        <MKTypography variant="h3" mb={1}>
                            TRIAS UKS
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FilledInfoCard
                            height="17rem"
                            color="success"
                            icon="medical_services"
                            title="Pelayanan Kesehatan"
                            description="Melalui pencegahan penyakit seperti dengan imunisasi dan minum obat cacing."
                            action={{
                                type: "internal",
                                route: "/program/pelayanan-kesehatan",
                                label: "Selengkapnya",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FilledInfoCard
                            height="17rem"
                            color="success"
                            icon="school"
                            title="Pendidikan Kesehatan"
                            description="Melalui kegiatan peningkatan pengetahuan secara intrakurikuler, kokurikuluer dan ekstrakurikuler dan pembiasaan PHBS."
                            action={{
                                type: "internal",
                                route: "/program/pendidikan-kesehatan",
                                label: "Selengkapnya",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FilledInfoCard
                            height="17rem"
                            color="success"
                            icon="webhook"
                            title="Pembinaan Lingkungan Sekolah Sehat"
                            description="Dengan melengkapi sarana prasarana PHBS, antara lain air bersih, toilet, tempat cuci tangan, tempat sampah, saluran drainase."
                            action={{
                                type: "internal",
                                route: "/program/pembinaan-lingkungan-sekolah-sehat",
                                label: "Selengkapnya",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default InformationCard;
