// eslint-disable
import { Grid, Typography } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { useState } from "react";
import axios from "axios";

import SearchTextPage from "./component/SearchTextPage";
import SearchListPage from "./component/SearchListPage";

function Pencarian() {
  const [loading, setLoading] = useState(false);
  const [aktif, setAktif] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [limitPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const group = ["berita", "agenda", "aktifitas_mitra"];

  const getData = (page_) => {
    setLoading(true);
    axios({
      url: `/search?page=${page_}&limit=${limitPage}&searchText=${searchText}`,
      method: "GET",
    }).then((e) => {
      const rows = e.data;
      setData(rows);

      setLoading(false);
    });
  };

  const handleSearch = () => {
    setAktif(true);
    setPage(page);
    getData(page);
  };

  return (
    <BaseLayout
      title="Pencarian"
      breadcrumb={[{ label: "Home", route: "/" }, { label: loading ? "Menuat..." : "Pencarian" }]}
    >
      <Grid container spacing={2} style={{ marginBottom: "2rem" }}>
        <Grid item md={12}>
          <SearchTextPage
            searchText={searchText}
            setSearchText={setSearchText}
            handleSearch={handleSearch}
          />
        </Grid>
        <Grid item md={12}>
          {aktif && loading && <div>Loading...</div>}
          {aktif && !loading && (
            <div>
              {group?.map((item) => {
                const dataChildren = data[item]?.data || [];

                return (
                  <div key={item} className="mb-4">
                    <h3 className="capital">{item.replace("_", " ")}</h3>
                    {dataChildren.length === 0 && <Typography variant="caption">Tidak ada data</Typography>}
                    {dataChildren.map((children) => (
                      <div key={children}>
                        <SearchListPage item={children} />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
    </BaseLayout>
  );
}

export default Pencarian;
