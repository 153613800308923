/* eslint-disable */
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "./index.css";

function BuiltByDevelopers(props) {
  const { slider } = props

  return (
    <MKBox
      width="100%"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }}) =>
        `${linearGradient(
          rgba('4caf50', 0),
          rgba('2F9933', 0)
        )}, url(/header_uks_new.png)`,
        // backgroundColor: 'white',
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
        minHeight: 450
      }}
    >
      <div
        style={{
          width: '100vw'
        }}
      >
          <Slide>
            {slider.map((opt, key) => (
              <div className="each-slide" key={key}>
                <MKBox
                  // borderRadius="xl"
                  sx={{
                    backgroundImage: `url(${opt.images})`,
                    backgroundSize: 'cover',
                    display: "grid",
                    // backgroundPosition: "center",
                    backgroundPosition: "top",
                    height: '25rem',
                    minHeight: 450,
                    "@media (max-width: 600px)": {
                      height: '12rem',
                      minHeight: '12rem',
                      backgroundSize: 'cover',
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }
                  }}>
                  {/* <Grid
                    xs={12}
                    lg={5}
                    sx={{
                      display: { xs: "none", lg: "block" },
                      padding: { xs: "2rem 3rem", lg: "2rem 0 2rem 3.3rem" }
                    }}
                    my={6}
                    py={6}
                    alignItems="center"
                  > */}
                  <div
                    style={{
                      marginTop: '3rem',
                      padding: '2rem 3rem',
                      display: 'flex',
                      width: '40%',
                      flexDirection: 'column',
                      justifyContent: 'left',
                      alignItems: 'left',
                      height: '100%'
                    }}
                  >
                    <MKTypography variant="h3" color="white" mb={1} style={{ fontSize: 22, textShadow: '2px 2px 10px #000' }}>
                      {opt?.title || ''}
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={1} mb={2}
                      style={{
                        fontSize: 15, textShadow: '2px 2px 10px #000',
                        fontWeight: '500'
                      }}
                      >
                      {opt?.konten || ''}
                    </MKTypography>
                    </div>
                  {/* </Grid> */}
                </MKBox>
              </div>
            ))}
          </Slide>
      </div>
    </MKBox>
  );
}

export default BuiltByDevelopers;
