/* eslint-disable */
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Button, Divider, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

import EventIcon from '@mui/icons-material/Event';
import { useState } from "react";

function DokumenCard({ image, title, action, align, rows, ...rest }) {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const isLarge = useMediaQuery(useTheme().breakpoints.up("lg"));

  const handleUnduh = async ({ route, nama, key, fileId }) => {
    axios({
      url: `/cUnduh/${fileId}`,
      method: "GET",
    });

    const urlPath = route.includes('http') ? route : `https://is3.cloudhost.id/storagedirectus1/${route}`

    var config = {
      method: "get",
      url: urlPath,
      responseType: "blob",
    };

    let response = await axios(config);
    const a = document.createElement("a");
    a.style.display = "none";
    a.key = `akey${key}`;
    document.body.appendChild(a);
    const blobFile = new Blob([response?.data], { type: response?.data?.type });
    const url = window.URL.createObjectURL(blobFile);
    a.href = url;
    a.download = nama;
    a.click();
    window.URL.revokeObjectURL(url);

    if(rest.viewCounter){
      handleLogAktifitas({ id: rows.id, type: 'download' });
    }
  }

  const handleOpen = ({ route }) => {
    if(rest.viewCounter){
      handleLogAktifitas({ id: rows.id, type: 'view' });
    }

   const urlPath = route.includes('http') ? route : `https://is3.cloudhost.id/storagedirectus1/${route}`
    const url = urlPath;
    window.open(url, '_blank');
  }

  const handleTanggal = (value) => {
    var mydate = new Date(value);
    var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
    var str = mydate.getDate() + ' ' + month + ' ' + mydate.getFullYear();

    return str;
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const handleLogAktifitas = async ({ id, type }) => {
    try {
        const isDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        const data = {
            tabel: rest.tabel,
            row_id: id,
            device: isDevice ? 'mobile' : 'desktop',
            browser: navigator.userAgent,
            type: type,
            url: window.location.href
        };

        await axios.get('set-log-aktifitas', { params: data });

    } catch (error) {
        console.error('Error logging aktivitas:', error);
    }
};

  return (
    <Card
      style={{
        marginBottom: '1.5rem'
      }}
    >
      <MKBox position="relative" borderRadius="lg" mx={1} mt={-1}>
        <Button
          disabled={action.route === ''}
          onClick={() => handleOpen(action)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            position: 'absolute',
            inset: '0px',
            zIndex: 2,
          }}
        />
        <MKBox
          sx={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            scale: isHovered ? '1.08' : '1',
            transition: 'all 0.3s ease-in-out',
          }}
          borderRadius="lg"
          width="100%"
          height={isMobile ? "210px" : "390px"}
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={2} mt={-2} textAlign={align || 'center'}>
        <MKBox mt={2} mb={3}>
          <MKTypography variant="caption"><div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}> <EventIcon />&nbsp;{handleTanggal(rows.tanggal_dokumen)}</div></MKTypography>
          <Tooltip title={title} placement="top">
              <Typography gutterBottom variant="h5" component="div" className="text-ellipsis-multiline" style={{ fontSize: 15, minHeight: 40 }}>
                  { title }
              </Typography>
          </Tooltip>
        </MKBox>
        { rest.viewCounter && (<div className="flex justify-between">
          <Typography variant="caption" className="text-13 text-silver italic text-bold" >Dilihat : {rest.c_view || 0} Kali</Typography>
          <Typography variant="caption" className="text-13 text-silver italic text-bold" >Unduh : {rest.c_download || 0} Kali</Typography>
        </div>
      )}
        <Divider style={{ margin: 0 }} />
        <div title={action.route === '' ? "Maaf file belum tersedia" : ''}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
          }}
        >
          <IconButton size="small" disabled={action.route === ''} onClick={() => handleOpen(action)}>
            <VisibilityIcon style={{ fontSize: '4px', color: '#009BDA' }} />&nbsp;<p style={{ fontSize: '15px', fontWeight: 'medium' }}>Lihat</p>
          </IconButton>
          <IconButton size="small" disabled={action.route === ''} onClick={() => handleUnduh(action)}>
            <DownloadIcon style={{ fontSize: '4px', color: '#1BBB05' }} />&nbsp;<p style={{ fontSize: '15px', fontWeight: 'medium' }}>Unduh</p>
          </IconButton>
        </div>
      </MKBox>
    </Card>
  );
}

DokumenCard.propTypes = {
  image: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default DokumenCard;
