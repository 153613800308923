/* eslint-disable */
import { Grid, IconButton, Card, TextField, ButtonGroup, Stack, Pagination, Skeleton, Table, TableBody, TableRow, TableCell } from "@mui/material";
import BaseLayout from "layouts/sections/components/BaseLayout";
import React, { useEffect, useState } from "react";
import axios from 'axios';
import MKButton from "components/MKButton";
import DokumenCard from "examples/Cards/BlogCards/DokumenCard";

// ICON
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate, useParams } from "react-router-dom";

function BukuPanduan() {
    const navigate = useNavigate();
    const { page } = useParams();
    const [countPage, setCountPage] = useState(1);
    const [publikasi, setPublikasi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [select, setSelect] = useState([]);
    const [checked, setChecked] = useState('');
    const [tampil, setTampil] = useState('grid'); // grid,list

    const limitPage = 12;
    const thisPage = parseInt(page) || 1;

    useEffect(() => {
        getData(thisPage);
    }, [checked, page]);

    const getData = (thisPage) => {
        setLoading(true);
        axios({
            url: `/get_publikasi/${thisPage}?halaman=buku-panduan&search=${search}&jenis_dokumen_id=${checked}&limit=${limitPage}`,
            method: 'GET',
        }).then((e) => {
            const { rows, count, select } = e.data;
            const data = Math.ceil(count/limitPage);
            setCountPage(data);
            setPublikasi(rows);
            setSelect(select.jenis_dokumen);
            setLoading(false);
        })
    }

    const handleTanggal = (value) => {
        if (value){
          var mydate = new Date(value);
          var month = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"][mydate.getMonth()];
          var str = mydate.getDate() +  ' ' + month + ' ' + mydate.getFullYear();
      
          return str;
        }
        else{
          return value
        }
    }

    const handleTampil = () => {
        const view = tampil === 'grid' ? 'list' : 'grid';
        setTampil(view);
    }

    const handleSearch = () => {
        getData(0);
    }

    const handleChange = (event, value) => {
        if(value === 1){
            navigate(`/dokumen/publikasi/buku-panduan`);
        }else{
            navigate(`/dokumen/publikasi/buku-panduan/${value}`);
        }
    }

    return (
        <BaseLayout
            title="Buku Panduan"
            breadcrumb={[
                { label: "Publikasi" },
                { label: "Buku Panduan" },
            ]}
        >
            <Grid container spacing={2} style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Grid container spacing={1} item md={12}>
                        <Grid item md={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    name="pencarian"
                                    size="small"
                                    fullWidth
                                    placeholder="Pencarian..."
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    onKeyPress={e => {
                                        if(e.key === 'Enter'){
                                            handleSearch()
                                        }
                                    }}
                                />
                                &nbsp;
                                <MKButton size="small" color="success" onClick={handleSearch}>Cari</MKButton>
                            </div>
                        </Grid>
                        <Grid item md={3.5}></Grid>
                        <Grid item md={0.5}>
                            <div style={{ display: 'flex', alignItems: 'right' }}>
                                <IconButton size="medium" onClick={() => handleTampil()}>
                                    { tampil === 'grid' ? <FormatListBulletedIcon/> : <GridViewIcon/> }
                                </IconButton>
                            </div>
                        </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={3}>
                        { !loading && publikasi.length === 0 && (<Grid item xs={12} md={12}><center style={{ margin: '3rem' }}>...Tidak ada data untuk di tampil...</center></Grid>) }
                        { loading && tampil === 'grid' && [1,2,3,4].map((opt, key) => <Grid key={key} item xs={6} md={3}><Skeleton variant="rectangular" width={"100%"} height={370} /></Grid>) }
                        { loading && tampil === 'list' && [1,2,3,4,5,6].map((opt, key) => <Grid key={key} item xs={12} md={12}><Skeleton variant="rectangular" width={"100%"} height={40} /></Grid>) }
                        {
                            !loading && tampil === 'grid' && publikasi.map((opt, key) => {
                                const link = opt.files.length !== 0 ? opt.files[0].path : '';
                                return (
                                    <Grid item xs={6} md={3} key={key}>
                                        <DokumenCard
                                            align="left"
                                            image={opt.cover ? opt.cover.includes('api-uks.kemdikbud.go.id') ? opt.cover : `https://is3.cloudhost.id/storagedirectus1/${opt.cover}`:"/ilustrasi-dokumen.png"}
                                            title={opt.nama}
                                            rows={opt}
                                            height={'260px'}
                                            viewCounter={true}
                                            tabel="publikasi"
                                            row_id={opt.publikasi_id}
                                            c_view={opt?.c_view}
                                            c_download={opt?.c_download}
                                            action={{
                                                type: "external",
                                                nama: opt.nama,
                                                route: link,
                                                key: key,
                                                fileId: opt.publikasi_id,
                                                color: "success",
                                                label: "Unduh",
                                            }}
                                        />
                                    </Grid>
                                )
                            })
                        }
                        {
                            !loading && publikasi.length !== 0 && tampil === 'list' && (
                                <Grid item xs={12} md={12}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><b>Nama</b></TableCell>
                                                <TableCell><b>Kategori</b></TableCell>
                                                <TableCell><b>Diupload</b></TableCell>
                                                <TableCell><b>&nbsp;</b></TableCell>
                                            </TableRow>
                                            {
                                                publikasi.map((opt, key) => {
                                                    return (
                                                        <TableRow key={key}>
                                                            <TableCell><b>{opt.nama}</b></TableCell>
                                                            <TableCell>{ opt.jenis_dokumen }</TableCell>
                                                            <TableCell>{ handleTanggal(opt.tanggal_dokumen) }</TableCell>
                                                            <TableCell>
                                                                {
                                                                    opt.files.map((opt, keyfile) => {
                                                                        <div key={keyfile}>
                                                                            <IconButton><DownloadIcon fontSize="medium"/></IconButton>
                                                                            <IconButton><VisibilityIcon fontSize="medium" /></IconButton>
                                                                        </div>
                                                                    })
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} md={12} container spacing={1} justifyContent="center" mx="auto">
                            <Stack spacing={2}>
                                <Pagination
                                    count={countPage}
                                    defaultPage={thisPage}
                                    color="secondary"
                                    showFirstButton
                                    showLastButton
                                    onChange={handleChange}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BaseLayout>
    )
}

export default BukuPanduan;
